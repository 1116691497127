import React from 'react';

import './link-button.styles.scss'

const LinkButton = ({ children, ...otherProps }) => (
  <a className='link-button' {...otherProps}>
    {children}
  </a>
)

export default LinkButton;
