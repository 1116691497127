import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../components/custom-button/custom-button.component';
import FooterImage from '../../components/footer-image/footer-image.component';
import Footer from '../../components/footer/footer.component';
import InfoCardTall from '../../components/info-card-tall/info-card-tall.component';
import InfoImage from '../../components/info-image/info-image.component';
import SectionBreak from '../../components/section-break/section-break.componenet';
import SignIn from '../../components/sign-in/sign-in.component';




import './home.styles.scss';

const Home = () => (
  <div className='home'>

    <div className='header-container'>
      <div className='header-image' style={{ backgroundImage: "linear-gradient(rgba(28, 39, 48, .2), rgba(28, 39, 48, .2)), url('assets/picsFromJordan/maui-kahului-harbor.jpg')" }}>
        <div className='content-container'>
          <div className='header-text-container'>
            <h1 className='header-text'>
              The Premiere Accounting Firm on Maui
            </h1>
            <div className='contact-button'>
              <Link to='/contact-us'>
                <CustomButton>Contact Us +</CustomButton>
              </Link>
            </div>
          </div>
          <div className="get-in-touch-container">
            <SignIn></SignIn>
          </div>
        </div>
      </div>
    </div>

    <div className='section odd'>

      <p className='section-text home-pad-edit'>
        Get every tax deduction you deserve when you work with the experts at James & Associates. We are a team of certified public accountants in Maui, HI, that have been serving the local area for over 25 years. Our team searches more than 350 tax deductions and credits, to ensure you get the maximum refund possible each and every year.
      </p>

      <div className='info-card-short-container'>

        <div className='info-card-short'>
          <div className='icon-container mobile-hide'>
            <img className='icon' src='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/noun_consultation_2664958+%284%29-1920w.png' alt='Free consultation' />
          </div>
          <div className="text-container">
            <p className='text-header'>
              Call us to today for your free consultation!
            </p>
            {/* <p className='text-header'>
              Call us to today to discuss tax deductions.
            </p> */}
            <a href='tel: (808) 244-2030'>
              <p className='text-content pad-number clickable' >
                (808) 244-2030
              </p>
            </a>
          </div>
        </div>

      </div>
    </div>

    <SectionBreak />

    <div className="section">
      <p className='title'>
        Our Services
      </p>
      <p className='subtitle'>
        We offer a variety of service options for our clients including:
      </p>
      <div className='info-card-tall-container'>
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/noun_Audit_2068904-640w.png'
          linkUrl='/services/audit-and-assurance'
          textHeader='Audit and Assurance '
          textContent='With clients on Maui, the Big Island, Kauai, and Molokai, we are capable of providing assurance services throughout the entire State of Hawaii. Our participation in the AICPA’s peer review program ensures our services and quality control meet the most stringent standards.'
          button='true'
        />
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/noun_tax_2754482-640w.png'
          linkUrl='/services/tax-preperation'
          textHeader='Tax Preparation '
          textContent='We have been preparing tax returns on Maui for over 25 years. We stay up to date with all the new laws, credits, and reporting requirements that change from year to year so we can offer you the most advantageous tax ideas, structures, and strategies. Our goal is to keep you in compliance and free of IRS scrutiny.'
          button='true'
        />
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/noun_consulting_2768451+%281%29-640w.png'
          linkUrl='/services/consulting-and-advisory'
          textHeader='Consulting and Advisory '
          textContent='Good CPAs can do far more than help you prepare your taxes once a year. They can also assist you with a wide variety of financial issues. If you’ve been mulling over a major business or personal financial decision, we can give you much needed advice.'
          button='true'
        />
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/noun_bookkeeping_1824330-640w.png'
          linkUrl='/services/bookkeeping'
          textHeader='Bookkeeping '
          textContent='We recognize it can be expensive to do business in Hawaii, which is why we will customize our services to fit your needs and budget.'
          button='true'
        />
        <InfoCardTall
          imageUrl='https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/noun_Flag_325266-640w.png'
          linkUrl='/services/irs-and-state-representation'
          textHeader='IRS and State Representation '
          textContent='Few pieces of mail inspire more dread than a notice from the State or the IRS. If the taxing authorities have been stressing you out, let us help.'
          button='true'
        />
      </div>
    </div>

    <SectionBreak />

    <div className="section odd">
      <p className='title'>
        Client Options
      </p>
      <p className='subtitle'>
        We are a full-service accounting firm comfortable working with a variety of clients including:
      </p>
      <div className='info-image-container'>
        <InfoImage
          imageUrl='assets/picsFromJordan/maui-shave-ice-business.jpg'
          alt=''
          textHeader='Businesses'
          textContent='Businesses can have a variety of more complex accounting needs ranging from tax preparation to bookkeeping. We are well-equipped to handle all of these services and more for small and mid-sized businesses in Hawaii.'
        />
        <InfoImage
          imageUrl='assets/picsFromJordan/maui-individual-family.jpg'
          textHeader='Individuals'
          textContent='No matter what your income, taxes can become complicated based on a variety of circumstances. We work with individuals on a case to case basis, analyzing every detail of your finances to create the best plan for you.'
        />
        <InfoImage
          imageUrl='assets/picsFromJordan/wailuku-neighborhood.jpg'
          textHeader='Homeowner Associations'
          textContent='Condominiums and timeshare associates have specific laws that apply to them including the need for annual audits. Learn more about the unique needs and how we can help on our homeowner association specific page.'
        />
        <InfoImage
          imageUrl='assets/picsFromJordan/non-profit-organizations.jpg'
          textHeader='Non-profit Organizations'
          textContent='Non-profits have unique financial laws surrounding them, and we know them all on both a federal and state level.'
        />
        <InfoImage
          imageUrl='assets/picsFromJordan/maui-estate.jpg'
          textHeader='Trusts and Estates'
          textContent='If you are an executor, trustee or fiduciary in need of financial help, we can guide you through the complex filing requirements needed.'
        />
        <InfoImage
          imageUrl='assets/picsFromJordan/phone-call.jpg'
          textHeader='Contact Us'
          textContent='No matter your tax needs or what type of client you are, rest assured we can help you!'
        />
      </div>
    </div>





    <FooterImage />
    <Footer />
  </div>
);

export default Home;