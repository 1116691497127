import React from 'react';

import './info-image.styles.scss'

const InfoImage = ({ imageUrl, textHeader, textContent }) => (
  <div className='info-image' >

    <div
      className='background-image'
      style={{
        backgroundImage: `url(${imageUrl})`
      }}
    />
    <div className='text-container'>
      <h1 className='text-header'>{textHeader}</h1>
      <span className='text-content'>{textContent}</span>
    </div>
    <div className='text-containter-title'>
      <h1 className='text-header'>{textHeader}</h1>
    </div>

  </div>
)

export default InfoImage;
