import React from 'react';

import logoSmall from '../../assets/logos/james-and-associates-plumeria-logo.png';

import './section-break.styles.scss'

const SectionBreak = () => (
  <div className='section-break'>
    <div className='logo-divider' style={{backgroundImage: `url(${logoSmall})`}}></div>
  </div>
)

export default SectionBreak;
