import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/custom-button/custom-button.component';
import FooterImage from '../../../components/footer-image/footer-image.component';
import Footer from '../../../components/footer/footer.component';
import SectionBreak from '../../../components/section-break/section-break.componenet';

import './tax-preperation.styles.scss'
const TaxPreperation = () => (
  <div className='tax-preperation'>

    <div className='header-container'>
      <div className='header-image' style={{ backgroundImage: "linear-gradient(rgba(28, 39, 48, .2), rgba(28, 39, 48, .2)), url('../assets/picsFromJordan/lahaina-maui-mountains.jpg')" }}>
        <div className='content-container'>
          <div className='header-text-container'>
            <h1 className='header-text'>
              Tax Preparation Services in Maui
            </h1>
            <div>
              <Link to='/contact-us'>
                <CustomButton className="inverted">Contact Us +</CustomButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='section odd'>
      <p className='section-text'>
        James & Associates has been preparing tax returns in Maui for over 25 years. Over this time, we have constantly adapted to new laws and ensured that no matter what changes, our commitment to our clients never will. Our goal has always been to keep everyone we work with in compliance and get them the best possible financial situation based on their unique situation. Learn more about the different types of returns we prepare and the benefits of enlisting an expert for this job below.
      </p>
    </div>

    <SectionBreak />

    <div className='section'>
      <div className='info-tile-container'>
        <div className='info-tile-left'>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Why use a CPA to file?
            </p>
            <p className='info-tile-content'>
              Filing taxes involves a variety of different forms and can become increasingly complex based on the amounts of assets involved. While a young, single individual with no investments can have a simple and straightforward filing experience, a large family or business will have much more work involved with their taxes. By enlisting a CPA such as James & Associates, not only will you not have to navigate all the complexities of this paperwork, but you also can rest assured that you are getting the best possible return. Our experts have been preparing taxes for years and know every avenue to check for bigger refunds and reduced payments. With an expert on your side, you will have a smooth and less pricy tax filing season.
            </p>
          </div>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url('../assets/picsFromJordan/tax-prep-deadline.jpg')`
            }}>
          </div>
        </div>

      </div>
    </div>

    <SectionBreak />

    <div className='section odd'>
      <div className='info-tile-container'>

        <div className='info-tile-right'>
          <div className='info-tile-picture'
            style={{
              backgroundImage: `url('../assets/picsFromJordan/tax-returns-accounting-meeting.jpg')`
            }}>
          </div>
          <div className='info-tile-text-container'>
            <p className='info-tile-title'>
              Types of returns we can file for you
            </p>
            <p className='info-tile-content'>
              Taxes are something that every individual and business need to pay annually. Here are some of the most common types of returns that we prepare for clients throughout Hawaii.
            </p>

            <ul>
              <li>Federal tax return preparation</li>
              <li>Income tax return preparation</li>
              <li>Small business tax preparation</li>
            </ul>

          </div>
        </div>

      </div>
    </div>

    <FooterImage>
      Contact us today for a free quote on tax prep services.
    </FooterImage>
    <Footer />

  </div>
)

export default TaxPreperation;