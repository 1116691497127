import React from 'react';
import Footer from '../../components/footer/footer.component';
import SectionBreak from '../../components/section-break/section-break.componenet';
import SignIn from '../../components/sign-in/sign-in.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'

import './contact-us.styles.scss'
const ContactUs = () => (
  <div className='contact-us'>

    <div className='header-container'>
      <div className='header-image' style={{ backgroundImage: "linear-gradient(rgba(28, 39, 48, .2), rgba(28, 39, 48, .2)), url('assets/picsFromJordan/business-handshake.jpg')" }}>
        <div className='content-container'>
          <div className='header-text-container'>
            <h1 className='header-text'>
              Contact the Accounting Experts in Maui, Today!
            </h1>
          </div>
          <div className="get-in-touch-container">
          </div>
        </div>
      </div>
    </div>

    <div className='section'>
      <div className='contact-info-container'>
        <div className='contact-info-content'>
          <p className='contact-info-text'>
            If you’re ready to work with the most experienced, financially savvy team in Hawaii, look no further! With over 25 years of experiencing serving businesses and individuals alike, we can handle all your needs, from simple tax filing to complex audits. Remember, your first consultation with us is always free, and if you can’t make it to our office, we can come to you! Use the contact form or give us a call for more information.
          </p>
          <p className='contact-info-title'>
            Contact information
          </p>
          <p className='contact-info-text bold'>
            James & Associates CPAS Inc
          </p>
          <p className='contact-info-text'>
            2158 Main St., #109
          </p>
          <p className='contact-info-text'>
            Wailuku, HI 96793
          </p>
          <p className='contact-info-text'>
            (808) 244-2030
          </p>
          <p className='contact-info-text'>
            admin@cpamaui.com
          </p>

          <p className='contact-info-title'>
            Hours of operation
          </p>
          <div className='hours-of-operation'>
            <p>Mon - Fri</p>
            <p className='right'>8:00 am - 5:00 pm</p>
            <p>Sat - Sun</p>
            <p className='right'>Closed</p>
          </div>

          <div className='social-info'>
            <a href='https://www.facebook.com/cpamaui/'
              rel='noreferrer'
              target='_blank'
              className='logo-container'>
              <FontAwesomeIcon icon={faFacebook} size='2x' className='fa-icon fb' />
            </a>

            <a href='https://www.google.com/maps/place/James+%26+Associates/@20.887491,-156.5061947,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xad3107a158a31087!8m2!3d20.887491!4d-156.504006'
              rel='noreferrer'
              target='_blank'
              className='logo-container'>
              <FontAwesomeIcon icon={faGlobeAmericas} size='2x' className='fa-icon location' />
            </a>
          </div>

        </div>
        <div className='vertical-rule'></div>
        <div className='contact-send-message'>
          <SignIn disclaimer='true'></SignIn>
        </div>
      </div>
    </div>

    <SectionBreak />

    <div className='section odd'>
      <div className='info-tile-left'>
        <div className='info-tile-text-container'>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11010.089507513218!2d-156.5110750409995!3d20.887407647775643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7954d36bd3021f4b%3A0x60c0ed2e51968842!2s2158%20Main%20St%2C%20Wailuku%2C%20HI%2096793!5e0!3m2!1sen!2sus!4v1631916025030!5m2!1sen!2sus"
            title='Google Map'
            width="100%"
            height="500"
            style={{ maxWidth: '1050px' }}
            allowFullScreen=""
            loading="lazy">
          </iframe>

        </div>
      </div>

    </div>

    <Footer />

  </div>
)

export default ContactUs;